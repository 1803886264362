.buttons {
	margin-left: auto;
}

.buttons > button {
	margin-left: 0.5rem;
}

.muted {
	animation: linear pulsate 1s infinite alternate;
}

@keyframes pulsate {
	from {
		transform: scale(1);
	}
	to {
		transform: scale(1.1);
	}
}
