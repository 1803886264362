// Override Bootstrap variables
$form-range-track-bg: #fff;
$btn-focus-box-shadow: none !important;
$form-range-thumb-focus-box-shadow: none !important;
$dark: #343a40;

// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Mixin override for fixing sticky iOS :hover
@mixin hover() {
  @media (hover: hover) {
    &:hover { @content; }
  }
}

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
// @import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
// @import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
// @import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
// @import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
// @import "~bootstrap/scss/card";
// @import "~bootstrap/scss/accordion";
// @import "~bootstrap/scss/breadcrumb";
// @import "~bootstrap/scss/pagination";
// @import "~bootstrap/scss/badge";
// @import "~bootstrap/scss/alert";
// @import "~bootstrap/scss/progress";
// @import "~bootstrap/scss/list-group";
// @import "~bootstrap/scss/close";
// @import "~bootstrap/scss/toasts";
// @import "~bootstrap/scss/modal";
// @import "~bootstrap/scss/tooltip";
// @import "~bootstrap/scss/popover";
// @import "~bootstrap/scss/carousel";
// @import "~bootstrap/scss/spinners";
// @import "~bootstrap/scss/offcanvas";
// @import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";

// Custom donate button colours
$donation-orange: #f4b04e;
.btn-donate {
  @include button-outline-variant($donation-orange);
  font-style: italic;
}

// Increase hitbox size for slider thumb by 4x
.form-range {
  &::-webkit-slider-thumb {
    background-clip: padding-box;
    border: 0.4rem solid transparent;
    transform: scale(4);
  }
  &::-moz-range-thumb {
    background-clip: padding-box;
    border: 0.4rem solid transparent;
    box-sizing: border-box;
    transform: scale(4);
  }
  &::-ms-thumb {
    background-clip: padding-box;
    border: 0.4rem solid transparent;
    transform: scale(4);
  }
}
